* {
  padding: 0;
  margin: 0;
}

body {
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: 2rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f4f4f4;
}

div {
  padding: 7px;
}

p {
  margin: 21px;
}

img {
  width: 50vw;
  margin: 21px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*** UI Elements ***/

button {
  text-align: none;
  font-size: 2rem;
  width: 100%;
  padding: 10px;
  margin: 10px;
  color: #fff;
  background-color: #03b2c7;
  line-height: 1.5em;
  border: solid 2px #03b2c7;
  -webkit-transition: background-color .2s ease 0s, background-color .2s ease 0s;
  -moz-transition: background-color .2s ease 0s, background-color .2s ease 0s;
  -ms-transition: background-color .2s ease 0s, background-color .2s ease 0s;
  -o-transition: background-color .2s ease 0s, background-color .2s ease 0s;
  transition: background-color .2s ease 0s, background-color .2s ease 0s;
  box-shadow: 3px 3px 28px #888888;
  border-radius: 14px;
}

button:hover {
  background-color: #278b9e;
}

fieldset {
  border-top: 1px solid #03b2c7;
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
  margin: 10px;
}

input[type="text"],
input[type="email"],
textarea {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  width: 100%;
  padding: 12px 20px;
  margin: 8px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  resize: none;
  font-family: 'Roboto Condensed', Arial, Verdana;
  font-size: 21px
}

input[type="text"]:focus,
textarea:focus {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  background-color: #ffffe0
}
@import url('//maxcdn.bootstrapcdn.com/font-awesome/4.2.0/css/font-awesome.min.css');

.info-msg,
.success-msg,
.warning-msg,
.error-msg {
    margin: 0;
    padding: 10px;
    border-radius: 7px 7px 7px 7px;
    display:block;
    font-size: 1rem;
}

.info-msg {
    color: #059;
    background-color: #BEF;
}

.success-msg {
    color: #270;
    background-color: #DFF2BF;
}

.warning-msg {
    color: #9F6000;
    background-color: #FEEFB3;
}

.error-msg {
    color: #D8000C;
    background-color: #FFBABA;
}

.section {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content:center;
    /*background-color: white;*/
}

.display-area-1 {
    /* Convert to flex */
    display: flex;
    flex-direction: column;
    /* Design */
    margin: 7px;
    width: 55vw;
    background-color: white;
    border-radius: 14px;
}

.display-area-2 {
    /* Convert to flex */
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    /* Design */
    margin: 7px;
    width: 35vw;
    background-color: white;
    border-radius: 14px;
}

.footer {
    width:92vw;
    margin:7px;
    background-color: white;
    border-radius: 14px;
}

.loaderimg {
    width: 100px;
}

.small_text {
    font-size: 0.75rem;
}

.centered_text {
    text-align:center;
}

.tsm_badge {
    width:100px;
}

.item-title {
    font-size: 1.5rem;
}

.item_image {
    width: 95%;
}

.item-desc {
    font-size: 1rem;
}

.user_details {
    font-size: 0.75rem;
    /* Convert to flex */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    /* Design */
    margin: 5px;
    background-color: #f4f4f4;
    border-radius: 14px;
}

.user_logo {
    padding:0;
    margin: 3px;
    display:inline-block;
}

.user_image {
    width: 100px;
    height: 100px;
}

.user_info {
    width: calc(100% - 170px);
    padding: 3px;
    margin: 3px;
    display: inline-block;
}

.pay_opts_title {
    font-size: 1.5rem;
}

#indianBank {
    display: none;
}

.qr {
    width: 210px;
    height: 210px;
    float: right;
}

@media only screen and (max-width: 840px) {
    /* For mobile phones: */
    [class*="display-area-"] {
        width: 90vw;
    }
 }
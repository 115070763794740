.App {
  text-align: center;
  width: 80vw;
  height: 500px;
  margin:auto;
}

.App-logo {
  pointer-events: none;
}

.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #333;
}

.App-link {
  color: #03b2c7;
}